import ResetCSS from "../common/assets/css/style";
import { DrawerProvider } from "../common/contexts/DrawerContext";
import { theme } from "../common/theme/webApp";
import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import CookieConsent from "react-cookie-consent";
import Navbar from "../containers/WebApp/Navbar";
import Footer from "../containers/WebApp/Footer";
// import Header from "./header/Header";
import "normalize.css";
import "../styles/typography.scss";
import Sticky from "react-stickynode";

import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from "../containers/WebApp/webApp.style";

export default function Layout({ children }) {
  const [open, setOpen] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <ResetCSS />
      <GlobalStyle />
      <AppWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <DrawerProvider>
            <Navbar />
          </DrawerProvider>
        </Sticky>
        <ContentWrapper>{children}</ContentWrapper>
        <Footer />
      </AppWrapper>

      <CookieConsent
        id="cookieConsent"
        buttonText="Accept"
        overlay
        style={{
          background: "#132636",
          textShadow: "2px 2px black",
        }}
        buttonStyle={{
          background:
            "linear-gradient( 145.76deg,rgb(244,186,79) -94.95%,rgb(244,116,33) 132.3% )",
          color: "white",
          borderRadius: "22.5px",
          fontWeight: "bolder",
        }}
      >
        This website use cookies to improve user experience and analyse website
        traffic.
      </CookieConsent>
    </ThemeProvider>
  );
}
